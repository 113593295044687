<template>
    <div>
        <navheader></navheader>
        <div>
            <div class="examBox">
                <div class="examContent">
                    <div class="crumbBox">
                        <p><i></i><a href="/">首页</a> > <a href="/#/QuestionBank">题库</a> > <span>{{paperData.practiceTitle}}</span></p>
                    </div>
                    <div v-if="checkPaper == ''" class="notfound">
                        
                    </div>
                    <div v-else>
                        <div class="titleBox">
                        <p class="title-top">
                            {{paperData.practiceTitle}}
                        </p>
                        <p class="title-sub">
                            本卷共{{allPaperList.length}}道试题作答时间为{{paperData.examLength}}分钟，总分{{paperData.fullMarks}}分，{{paperData.passScore}}分及格
                        </p>
                    </div>
                    <div class="examContentBox" id="examContentBox">
                        <div class="leftBox">
                            <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
                                <div class="questionContent" v-for="(item, index) in allPaperList" :key="item.id" :id="'id'+index">
                                    <div class="question-title">
                                        <p>
                                            <!-- <span class="font-bold">{{index+1}}、</span> -->
                                            <span class="font-bold" v-if="item.qType == 'single'">【单选题】</span>
                                            <span class="font-bold" v-if="item.qType == 'multi'">【多选题】</span>
                                            <span class="font-bold" v-if="item.qType == 'answer'">【问答题】</span>
                                            <span class="font-bold" v-if="item.qType == 'judge'">【判断题】</span>
                                            <span v-html="item.subject">{{item.subject}}</span>
                                        </p>
                                    </div>
                                    <div class="VSTD_box_item_select" v-if="item.qType == 'single'">
                                            <el-form-item label="" prop="resource">
                                                <el-radio-group v-model="questionArrText[index].selectAnswer">
                                                    <el-radio v-for="it of item.contList" :key="it.id" :label="it.optionValue" @change="getIputValue(it,it.id)">{{it.option}}</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                    </div>
                                    <div :class="item.qType == 'multi'? 'VSTD_box_item_mutil':'VSTD_box_item_select'" v-if="item.qType == 'multi'">
                                            <el-form-item label="" prop="resource">
                                                <el-checkbox-group v-model="questionArrText[index].selectAnswer" @input="change($event)">
                                                    <el-checkbox class="answer-checkbox" v-for="it of item.contList" :key="it.id" :label="it.optionValue" @change="getIputValue(it,it.id)">{{it.option}}</el-checkbox>
                                                </el-checkbox-group>
                                            </el-form-item>
                                    </div>
                                    <div :class="item.qType == 'multi'? 'VSTD_box_item_mutil':'VSTD_box_item_select'" v-if="item.qType == 'answer'">
                                            <el-form-item label="" prop="resource">
                                                <el-input
                                                    type="textarea"
                                                    v-model="questionArrText[index].selectAnswer"
                                                ></el-input>
                                            </el-form-item>
                                    </div>
                                    <div :class="item.qType == 'multi'? 'VSTD_box_item_mutil':'VSTD_box_item_select'" v-if="item.qType == 'judge'">
                                        <el-form-item label="" prop="resource">
                                            <el-radio-group v-model="questionArrText[index].selectAnswer">
                                                <el-radio v-for="it of item.contList" :key="it.id" :label="it.optionValue" @change="getIputValue(it,it.id)">{{it.option}}</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                </div>
                            </el-form>
                        </div>
                        <div class="rightBox" :class="{suspension:isfixTab}">
                            <div class="card-content-list">
                                <div class="time-box">
                                    <p><i></i>用时 ：<span>{{hour? hourString+':'+minuteString+':'+secondString : minuteString+':'+secondString}}</span></p>
                                </div>
                                <div class="card-content">
                                    <div class="card-main-title">
                                        <div class="border"></div>
                                        <p class="border-left">答题卡</p>
                                        <p class="border-sub">共<span>{{allPaperList.length}}</span>题</p>
                                    </div>
                                    <div class="box-list">
                                        <div v-for="(item,index) in allPaperList" :key="index" class="box-ul">
                                                <div class="checki" :class="{'checked': questionArrText[index].selectAnswer != null && questionArrText[index].selectAnswer != ''}" @click="handleLeft(index)">
                                                    {{index+1}}
                                                </div>
                                        </div>
                                    </div>
                                    <div class="sendButton">
                                        <a href="javascript:;" @click="submitForm()">
                                            提交试卷
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <navfooter></navfooter>
    </div>
</template>
<script>
import navheader from '../components/NavHeader'
import navfooter from '../components/NavFooter'
// import qs from 'qs'
export default {
    data() {
        return {
            obId: this.$route.params.obId,
            // uuId: this.$route.params.uuId,
            type: this.$route.params.type,
            courseContentId: this.$route.params.courseContentId,
            //倒计小时
            hour: '',
            //倒计分钟
            minute: '',
            //倒计秒
            second: '',
            //计时器
            promiseTimer: '',
            useTime: 0,
            paperData:{},
            allPaperList: [], // 总的题目
            disabledAnswer: false, // 禁止答题
            ruleForm: { // 考生答案
                resource: [],
            },
            questionArrText: [], // 答题保存的数组
            checkResult: false, // 左侧栏、右侧栏、答题结果栏
            resultId: '',
            isfixTab: false,
            navgatorIndex: 0,
            checkPaper: ''
        }
    },
    metaInfo: {
        title: '试卷-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    components: {
        navheader,
        navfooter,
    },
    created() {
        // this.init();
    },
    mounted() {
        this.getPaper();
        this.getIputValue();
        window.addEventListener('scroll', this.handleScroll);
        this.init();
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
        if (this.promiseTimer) {
            clearInterval(this.promiseTimer);
        }
        this.stopInit();
    },
    computed: {
      hourString () {
        return this.hour < 10 ? '0' + this.hour : '' + this.hour
      },
      minuteString () {
        return this.minute < 10 ? '0' + this.minute : '' + this.minute
      },
      secondString () {
        return this.second < 10 ? '0' + this.second : '' + this.second
      }
    },
    methods: {
        /**
       * 倒计时
       */
      countDowm () {
        let self = this;
        this.promiseTimer = setInterval(function () {
          if(self.hour===0 && self.minute===0 && self.second===0)
          {
            self.disabledAnswer=true;
          }
          if (self.hour === 0) {
            if (self.minute !== 0 && self.second === 0) {
              self.second = 59
              self.minute -= 1
            } else if (self.minute === 0 && self.second === 0) {
              self.second = 0
            } else {
              self.second -= 1
            }
          } else {
            if (self.minute !== 0 && self.second === 0) {
              self.second = 59
              self.minute -= 1
              self.useTime += 1
            } else if (self.minute === 0 && self.second === 0) {
              self.hour -= 1
              self.minute = 59
              self.second = 59
              self.useTime = 0
            } else {
              self.second -= 1
            }
          }
        }, 1000)
      },
        handleScroll () {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            var offsetTop = document.querySelector('#examContentBox').offsetTop
            if (scrollTop > offsetTop) {
                this.isfixTab = true
            } else {
                this.isfixTab = false
            }
            // console.log(scrollTop,offsetTop)
        },
        
        handleLeft(index) {
            this.navgatorIndex = index;
            this.$el.querySelector(`#id${index}`).scrollIntoView({
                behavior: "instant",  // 平滑过渡
                block:    "start"  // 上边框与视窗顶部平齐。默认值
            });
            // dom滚动位置
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            // 获取监听元素距离视窗顶部距离
            var offsetTop = document.getElementById(`id${index}`).offsetTop;
            // 获取监听元素本身高度
            var scrollHeight = document.getElementById(`id${index}`).scrollHeight;

            // 如果 dom滚动位置 >= 元素距离视窗距离 && dom滚动位置 <= 元素距离视窗距离+元素本身高度
            // 则表示页面已经滚动到可视区了。
            if (scrollTop >= offsetTop && scrollTop<=(offsetTop+scrollHeight)) {
                // 导航栏背景色选中
                // console.log("到了")
            }
        },
        // 提交试卷
        submitForm() {
            var arr = [];
            var that = this;
            // var b = this.ruleForm.resource.notempty();
            // if (b.length !== this.questionArrText.length) {
                //要是有未答的题目走if
                this.$confirm(
                    '"确定交卷吗?"',
                    '确认信息',
                    {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: "warning"
                    }
                )
                .then(() => {
                    arr = [];
                    //逻辑和刚刚的一样，
                    for (var i = 0; i < this.questionArrText.length; i++) {
                        var json = {};
                        json.qType = that.questionArrText[i].qType;
                        // if(that.uuId != 0) {
                            json.selectAnswer = that.questionArrText[i].selectAnswer;
                            // console.log(json.selectAnswer,"打印")
                            if( json.qType == "multi"){
                                if(json.selectAnswer.length === 0){
                                    continue;
                                }
                                let a = '';
                                for(let i = 0;i < json.selectAnswer.length;i++){
                                    if(i != json.selectAnswer.length -1){
                                        a += json.selectAnswer[i] + ','
                                    }else{
                                        a += json.selectAnswer[i] 
                                    }
                                }
                                json.selectAnswer = a;
                            }else{
                                if(json.selectAnswer === '' || json.selectAnswer == null) {
                                    continue;
                                }
                            }
                        
                        json.qType = that.questionArrText[i].qType;
                        if(json.qType != 'answer') {
                            json.answer = that.questionArrText[i].answer;
                        }
                        json.questionId = that.questionArrText[i].questionId;
                        json.score = that.questionArrText[i].qScore;
                        json.curNo = i+1;
                        
                        // if(json.selectAnswer != '') {
                            
                        // }
                        arr.push(json)
                        // console.log(json,"查看答案")
                    }
                    var arrString = JSON.stringify(arr)
                    var obj = {
                        // uuId: this.paperData.uuId,
                        obId: this.paperData.obId,
                        obName: this.paperData.practiceTitle,
                        total: this.paperData.totalPractice,
                        courseContentId: this.courseContentId,
                        courseId: this.paperData.courseId,
                        sType: this.paperData.sType,
                        list: arrString,
                        answerTime: this.useTime
                    }
                    // console.log(obj.answerTime);
                    this.axios({
                        method: 'POST',
                        url: 'api/v2/yun/answer/to_submit.html',
                        data: obj,
                    }).then((res) => {
                        // console.log(res)
                        this.errorCode = res.data.code;
                        // console.log(this.resultId);
                        if(this.errorCode == 500) {
                            this.$message.error('您还有题目未完成，请继续完成')
                        } else {
                            this.stopInit()
                            this.resultId = res.data.result.resultId
                            this.$router.push({
                                name: 'submitresults',
                                params:{resultId: this.resultId}
                            })
                        }
                    })
                }).catch(() => {
                              
                });
            // }
        },
        change() {
            this.$forceUpdate()
        },
        getIputValue() {
            // console.log(this.ruleForm.resource)
        },
        // 停止定时器
        stopInit(){
            clearInterval(this.time)
        },
        // 定时器
        init() {
            var arr = [];
            var that = this;
            // if(){}
            that.time = setInterval(() => {
                arr = [];
                for (var i = 0; i < this.questionArrText.length; i++) {
                        var json = {};
                        json.qType = that.questionArrText[i].qType;
                        // if(that.uuId != 0) {
                            
                            json.selectAnswer = that.questionArrText[i].selectAnswer;
                            if( json.qType == "multi"){
                                if(json.selectAnswer.length === 0){
                                    continue;
                                }
                                let a = '';
                                for(let i = 0;i < json.selectAnswer.length;i++){
                                    if(i != json.selectAnswer.length -1){
                                        a += json.selectAnswer[i] + ','
                                    }else{
                                        a += json.selectAnswer[i] 
                                    }
                                }
                                json.selectAnswer = a;
                            }else{
                                if(json.selectAnswer === '' || json.selectAnswer == null) {
                                    continue;
                                }
                            }
                        // } 
                        // else {
                        //     if (that.ruleForm.resource[i] instanceof Array) {
                        //         json.selectAnswer = that.ruleForm.resource[i].join();
                        //         console.log(json.selectAnswer,"处理");
                        //     } else {
                        //         json.selectAnswer = that.ruleForm.resource[i]
                        //         console.log(json.selectAnswer,"处理2");
                        //     }
                        //     if(Object.keys(json.selectAnswer).length === 0) {
                        //         continue;
                        //     }
                        // }
                        
                        if(json.qType != 'answer') {
                            json.answer = that.questionArrText[i].answer;
                        }
                        // console.log(json.qType,"类型")
                        json.questionId = that.questionArrText[i].questionId;
                        json.score = that.questionArrText[i].qScore;
                        json.curNo = i+1;
                        arr.push(json)
                    }
                    var arrString = JSON.stringify(arr)
                    var obj = {
                        // uuId: this.paperData.uuId,
                        obId: this.paperData.obId,
                        list: arrString
                    }
                    // console.log(obj, "定时结果")
                    this.axios({
                        method: 'POST',
                        url: 'api/v2/yun/answer/save_to_redis.html',
                        data: obj,
                    }).then(() => {
                        // this.paperData.uuId = res.data.result.uuId;
                        arr.splice(0,arr.length);
                        
                    })
            }, 5000)
        },
        // 获取试卷
        getPaper() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/answer/practice.html',
                data:{
                  obId: this.obId,
                //   uuId: this.uuId,
                  sType: this.type,
                  courseContentId: this.courseContentId
                },
                // headers: {
                //     'Content-Type': 'application/x-www-form-urlencoded'
                // }
            }).then((res) => {
                this.checkPaper = res.data.code;
                this.allPaperList = res.data.result.list;
                this.paperData = res.data.result;
                for (let i = 0; i < this.allPaperList.length; i++) {
                    if (this.allPaperList[i].qType == 'multi') {
                        this.ruleForm.resource[i] = [];
                        // this.questionArrText[i].selectAnswer = []
                    }else{
                        this.ruleForm.resource[i] = '';
                        // this.questionArrText[i].selectAnswer = ''
                    }
                }
                this.questionArrText = res.data.result.list; // 重新赋值
                // console.log(this.questionArrText,"试卷")
                // this.ruleForm.resource = this.questionArrText;
                // console.log(this.questionArrText,"试卷")
                // if(this.uuId != 0) {
                //     this.questionArrText = res.data.result.list; // 重新赋值
                //     // let dataAll = this.questionArrText;
                //     this.ruleForm.resource = this.questionArrText;
                // } else {
                //     this.questionArrText = res.data.result.list; // 重新赋值
                // }
                let remainTime = this.paperData.examLength*60;
                if (remainTime> 0) {
                    this.hour = Math.floor((remainTime / 3600) % 24)
                    this.minute = Math.floor((remainTime / 60) % 60)
                    this.second = Math.floor(remainTime % 60)
                    this.countDowm()
                }
            })
        }
    }
}
</script>
<style lang="scss">
    .examBox {
        padding-top: 90px;
        padding-bottom: 80px;
        box-sizing: border-box;
        width: 100%;
        min-width: 1200px;
        background: #EEEEEE;
        .examContent {
            .crumbBox {
                margin: 0 auto;
                padding-left: 40px;
                box-sizing: border-box;
                width: 1200px;
                height: 70px;
                line-height: 70px;
                background: #FFFFFF;
                border-radius: 10px;
                p {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    a {
                        color: #666666;
                    }
                    span {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FF5A74;
                    }
                }
                .notfound {
                    margin: 0 auto;
                    width: 1200px;
                }
                
            }
            .titleBox {
                margin: 0 auto;
                width: 1200px;
                height: 122px;
                background: #FFFFFF;
                border-radius: 10px;
                text-align: center;
                padding-top: 27px;
                box-sizing: border-box;
                margin-bottom: 20px;
                .title-top {
                    margin-bottom: 18px;
                    font-size: 28px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                }
                .title-sub {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                }
            }
            .examContentBox {
                margin: 0 auto;
                width: 1200px;
                display: flex;
                justify-content: space-between;
                .leftBox {
                    width: 915px;
                    background: #F9F9FA;
                    border-radius: 0px 0px 10px 10px;
                    .questionContent {
                        .question-title {
                            padding: 31px 29px;
                            box-sizing: border-box;
                            background: #F9F9FA;
                            display: inline-block;
                            p {
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                color: #333333;
                                line-height: 30px;
                                display: inline-block;
                                .font-bold {
                                    font-weight: bold;
                                }
                            }
                        }
                        .VSTD_box_item_select {
                            padding-top: 30px;
                            padding-left: 50px;
                            box-sizing: border-box;
                            width: 915px;
                            // height: 190px;
                            background: url('../assets/img/paperBg.jpg') no-repeat center;
                            .el-radio {
                                display: block;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                                margin-bottom: 25px;
                                text-overflow: ellipsis;
                                white-space: normal;
                            }
                            .answer-checkbox {
                                display: block;
                                margin-bottom: 11px;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                                text-overflow: ellipsis;
                                white-space: normal;
                            }
                        }
                        .VSTD_box_item_mutil {
                            padding-top: 3px;
                            padding-left: 50px;
                            box-sizing: border-box;
                            width: 915px;
                            // height: 190px;
                            background: #FFFFFF;
                            .el-radio {
                                display: block;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                                margin-bottom: 25px;
                            }
                            .answer-checkbox {
                                display: block;
                                margin-bottom: -5px;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                            }
                            .el-form-item {
                                .el-form-item__content {
                                    .el-textarea {
                                        width: 500px;
                                        .el-textarea__inner {
                                            height: 130px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .suspension {
                    position: fixed;
                    top: 80px;
                    right: 351px;
                    z-index: 99999;
                }
                .rightBox {
                    width: 265px;
                    // background: #FFFFFF;
                    border-radius: 10px;
                    .card-content-list {
                        .time-box {
                            padding-left: 24px;
                            box-sizing: border-box;
                            margin-bottom: 7px;
                            height: 72px;
                            background: #FFFFFF;
                            border-radius: 10px;
                            line-height: 72px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                            span {
                                color: #FA4B66;
                            }
                        }
                        .card-content {
                            background: #FFFFFF;
                            border-radius: 10px;
                            padding-top: 21px;
                            padding-bottom: 40px;
                            padding-left: 20px;
                            padding-right: 20px;
                            box-sizing: border-box;
                            .card-main-title {
                                padding-bottom: 13px;
                                margin-bottom: 20px;
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #666666;
                                display: flex;
                                align-items: center;
                                border-bottom: 1px solid #EEEEEE;
                                .border {
                                    margin-right: 10px;
                                    width: 5px;
                                    height: 20px;
                                    background: #FA4B66;
                                }
                                .border-left {
                                    margin-right: 11px;
                                }
                                .border-sub {
                                    font-size: 12px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #FA4B66;
                                }
                                span {
                                    color: #FA4B66;
                                }
                            }
                            .box-list {
                                margin-bottom: 20px;
                                display: flex;
                                flex-wrap: wrap;
                                .box-ul {
                                    .checki {
                                        width: 30px;
                                        height: 25px;
                                        border: 1px solid #DEDEDE;
                                        border-radius: 3px;
                                        line-height: 25px;
                                        text-align: center;
                                        cursor: pointer;
                                        margin-right: 5px;
                                        margin-bottom: 6px;
                                        font-size: 12px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #666666;
                                    }
                                    .checked {
                                        color: #666666;
                                        background: #DEDEDE;
                                    }
                                }
                                
                            }
                        }
                    }
                    .sendButton {
                        a {
                            margin: 0 auto;
                            display: block;
                            width: 225px;
                            height: 50px;
                            background: linear-gradient(90deg, #FA4B66, #FA8D4B);
                            border-radius: 10px;
                            line-height: 50px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FFFFFF;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
</style>
